jQuery(function () {

    function setup_navbar() {
        is_touch = $('html').hasClass('touch');

        // Make sure to hide expanded navbar if page width is over the lg breakpoint in bootstrap
        $(window)
            .on('resize', function () {
                app.viewport.changed(function () {
                    if (app.viewport.is('>=lg')) {
                        $('#navbar').removeClass('show');
                    }
                })
            });
    }

    function setup_sparklesFX() {
        // For the nav links    
        if (!is_touch) {

            var star_colr = $('.homepage').length ? '#ffffff' : '#666666';

            // header planet and stars animations on logo mouseover
            $("#logo")
                .on('mouseenter', function () {
                    if (app.viewport.is('<md')) return;

                    $("#planet, #star1, #star2, #star3, #star4, #star5").stop().fadeIn("slow");
                    $("#planet, #star1, #star2, #star3, #star4, #star5").addClass("zoom-tilt:hover");
                })
                .on('mouseleave', function () {
                    $("#planet, #star1, #star2, #star3,#star4, #star5").stop().fadeOut("slow");
                });

            $('#nav-main .nav-item').sparkle({
                color: star_colr,
                count: 10,
                overlap: 1,
                speed: 0,
                minSize: 6,
                maxSize: 8,
            });
            $('#sidebar .nav-item').sparkle({
                color: '#666666',
                count: 10,
                overlap: 1,
                speed: 0,
                minSize: 6,
                maxSize: 8,
            });
        }

        // offscreen nav
        // $('[data-toggle="offcanvas"]').click(function () {});
    }
    
    // Display page alerts
    if ($("#alerts-page").length) {
        $("#alerts-page").ufAlerts();
        $("#alerts-page").ufAlerts('fetch').ufAlerts('render');
    }
    
    // Link all copy buttons for alerts
    $.uf.copy('.js-copy-trigger');

    setup_navbar();
    setup_sparklesFX();
});

// Globals
var app = [];
var _bs = {
    'xs': $('<div class="device-xs d-block d-sm-none"></div>'),
    'sm': $('<div class="device-sm d-none d-sm-block d-md-none"></div>'),
    'md': $('<div class="device-md d-none d-md-block d-lg-none"></div>'),
    'lg': $('<div class="device-lg d-none d-lg-block d-xl-none"></div>'),
    'xl': $('<div class="device-xl d-none d-xl-block"></div>')
};

app.viewport = ResponsiveBootstrapToolkit;
app.viewport.use('Custom', _bs);

var is_touch = false;

// IIFE - stuff that doesn't need to be in global namespace
(function () {
    function isTouchDevice() {
        try {
            document.createEvent('TouchEvent');
            return true;
        } catch (e) {
            return false;
        }
    }

    if (isTouchDevice()) {
        document.documentElement.classList.add('touch');
    }
})();